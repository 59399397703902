import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons/faEnvelope';

// import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
// import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
// import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
// See https://fontawesome.com/icons?d=gallery&s=brands,regular&m=free
// to add other icons.
// {
//   link: 'https://facebook.com/md',
//   label: 'Facebook',
//   icon: faFacebookF,
// },
// {
//   link: 'https://www.instagram.com/dangelosaurus/',
//   label: 'Instagram',
//   icon: faInstagram,
// },
// {
//   link: 'https://twitter.com/dangelosaurus',
//   label: 'Twitter',
//   icon: faTwitter,
// },

const data = [
  {
    link: 'https://www.linkedin.com/in/ethan-meister',
    label: 'LinkedIn',
    icon: faLinkedinIn,
  },
  {
    link: 'mailto:ethanjmeister@gmail.com',
    label: 'Email',
    icon: faEnvelope,
  },
];

export default data;
